<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
    />
    <TextTemplateForm
      :errors="violations"
      :values="item"
      ref="createForm"
      :loading="isLoading"
    />
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {createHelpers} from 'vuex-map-fields';
import TextTemplateForm from '../../components/textTemplate/Form.vue';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';

const servicePrefix = 'TextTemplate';

const {mapFields} = createHelpers({
  getterType: 'textTemplate/getField',
  mutationType: 'textTemplate/updateField'
});

export default {
  name: 'TextTemplateCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    TextTemplateForm,
  },
  data() {
    return {
      item: {
        name: null,
        content: null,
      }
    };
  },
  computed: {
    ...mapFields(
      ['error', 'isLoading', 'created', 'deleted', 'violations']
    ),
  },
  methods: {
    ...mapActions('textTemplate', {
      create: 'create',
    }),
  },
}
</script>
