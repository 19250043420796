<template>
  <v-form>
    <v-card max-width="800px"
            class="mt-4 mx-auto"
            :loading="loading"
            :disabled="loading"
    >
      <v-card-title v-text="$t('TextTemplate')"/>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="nameErrors"
                :label="$t('name')"
                @blur="$v.item.name.$touch()"
                @input="$v.item.name.$touch()"
                v-model="item.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <InputEditor
                v-model="item.content"
                :error-messages="contentErrors"
                :label="$t('content')"
                :required="true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import has from 'lodash/has';
import {required} from 'vuelidate/lib/validators';
import InputEditor from "@/components/InputEditor";

export default {
  name: 'TextTemplateForm',
  mixins: [validationMixin],
  components:{
    InputEditor
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },

    loading: {
      type: Boolean,
      required: true
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    },

    handleSubmit: {
      type: Function,
      required: false
    },

    handleReset: {
      type: Function,
      required: false
    },
  },
  mounted() {
  },
  data() {
    return {
      name: null,
      content: null,
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, 'name') && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    contentErrors() {
      const errors = [];

      if (!this.$v.item.content.$dirty) {
        return errors;
      }

      has(this.violations, 'content') && errors.push(this.violations.content);

      !this.$v.item.content.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    violations() {
      return this.errors || {};
    }
  },
  methods: {
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      content: {
        required
      },
    }
  },
};
</script>
